import React from 'react'
import TagBadge from 'components/shared/tags/badge'

const I18n = window.I18n

function Direct({ number, name, regionName, action, address, showRegions }) {
  address = address ? address.toJS() : {}

  let addressString = ''
  if (Object.keys(address).length > 0) {
    let addressParts = [
      address.line1,
      address.line2,
      address.zip,
      address.city,
      address.country,
    ]

    addressParts = addressParts.filter((part) => part !== null && part !== undefined)

    if (addressParts.length > 0) {
      addressString = addressParts.join(', ')
    }
  }
  return (
    <tr>
      <td>{number}</td>
      <td>{name}</td>
      {showRegions && <td>{regionName}</td>}
      <td>{addressString}</td>
      <td className="text-center">
        <a onClick={action} className="action-control">
          {I18n.t('application.actions.select')}
          &nbsp;
          <i className="fa fa-angle-double-right"></i>
        </a>
      </td>
    </tr>
  )
}

function Reverse({ id, number, name, action, tags, approvedLocationIDs, campaignLocationsNeedApproval }) {
  tags = tags ? tags.toJS() : []

  return (
    <tr>
      <td className="text-center">
        {tags.length == 0 && (
          <a onClick={action} className="action-control">
            <i className="fa fa-angle-double-left"></i>
            &nbsp;
            {I18n.t('application.actions.remove')}
          </a>
        )}
      </td>
      <td>{number}</td>
      <td>
        {name}
        {tags.map((tag) => (
          <TagBadge name={tag.name} type={tag.type} key={`tag-key-${tag.value}`} />
        ))}
      </td>
      {campaignLocationsNeedApproval && (
        <td>
          {approvedLocationIDs.includes(id) ? (
            <TagBadge
              name={I18n.t('activerecord.attributes.elements/base_element.approved.approved_yes')}
              type={'Tags::Approved::Yes'}
              key={`tag-key-yes-${id}`}
            />
          ) : (
            <TagBadge
              name={I18n.t('activerecord.attributes.elements/base_element.approved.approved_no')}
              type={'Tags::Approved::No'}
              key={`tag-key-no-${id}`}
            />
          )}
        </td>
      )}
    </tr>
  )
}

const DIRECTIONS = { direct: Direct, reverse: Reverse }

export default class RowItem extends React.Component {
  handleAction = () => {
    this.props.action(this.props.item.get('id'))
  }

  render() {
    const { item, direction, showRegions, approvedLocationIDs, campaignLocationsNeedApproval } = this.props
    const RowItemView = DIRECTIONS[direction] || Direct

    return (
      <RowItemView
        id={item.get('id')}
        number={item.get('number')}
        name={item.get('name')}
        address={item.get('address')}
        regionName={item.get('regionName')}
        tags={item.get('matchedTags')}
        action={this.handleAction}
        showRegions={showRegions}
        approvedLocationIDs={approvedLocationIDs}
        campaignLocationsNeedApproval={campaignLocationsNeedApproval}
      />
    )
  }
}
