import classNames from 'classnames'

export const COLOR_BY_TYPE = {
  'Tags::Manual' : 'green',
  'Tags::Auto' : 'blue',
  'Tags::BrandTag' : 'yellow',
  'Tags::Approved::Yes' : 'green',
  'Tags::Approved::No' : 'red'
}

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'inline-block',
  backgroundColor: color,
  borderRadius: 10,
  content: '" "',
  marginRight: 8,
  marginLeft: 4,
  height: 10,
  width: 10,
  zIndex: 99
});

export default function TagBadge(props) {
  const color = COLOR_BY_TYPE[props.type] || 'white'

  return (
    <span className={classNames(['badge', 'badge-info', 'tag-badge'])}>
      <span style={dot(color)}>&nbsp;</span>
      {props.name}
    </span>
  )
}
