import React from 'react'
import RowItem from './row_item'

const I18n = window.I18n

export default class TargetBox extends React.Component {
  render() {
    const { items, removeItem, removeAll, approvedLocationIDs, campaignLocationsNeedApproval } = this.props
    return (
      <table className="table table-bordered table-hover table-striped table-sm">
        <thead className="">
          <tr>
            <th className="w-20">
              <a onClick={removeAll} className="action-control">
                <i className="fa fa-angle-double-left"></i>
                &nbsp;
                {I18n.t('application.actions.remove_all')}
              </a>
            </th>
            <th>{I18n.t('activerecord.attributes.elements/base_element.location_number')}</th>
            <th className="w-30">{I18n.t('activerecord.models.location.other') } ({items.size})</th>
            {campaignLocationsNeedApproval && (
              <th>{I18n.t('activerecord.attributes.elements/base_element.approved.approved')}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <RowItem
              item={item}
              direction="reverse"
              key={`item-key-${item.get('id')}`}
              action={removeItem}
              approvedLocationIDs={approvedLocationIDs}
              campaignLocationsNeedApproval={campaignLocationsNeedApproval}
            />
          ))}
        </tbody>
      </table>
    )
  }
}
