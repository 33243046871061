import * as React from 'react'

const { I18n } = window as any

type ElementInputProps = {
  element: any
  updateElement: (element: any) => void
  field: string
  name: string
}

type InputFactoryProps = {
  field: string
  name: string
  value: any
  editable: boolean
  handleInputChange: (e: any) => void
}

function InputFactory(props: InputFactoryProps) {
  const { field, name, value, editable, handleInputChange } = props

  const cssClass = `element-field-${field} form-control`
  if (field === 'note') {
    return (
      <textarea
        className={cssClass}
        name={name}
        defaultValue={value}
        disabled={!editable}
        onChange={(e) => handleInputChange(e)}
      />
    )
  } else {
    return (
      <input
        className={cssClass}
        required={field === 'amount'}
        min={ field === 'amount' ? 1 : undefined }
        type="number"
        name={name}
        defaultValue={value}
        disabled={!editable}
        onChange={(e) => handleInputChange(e)}
      />
    )
  }
}

export default function ElementInput(props: ElementInputProps) {
  const { element, updateElement, field, name } = props

  const handleInputChange = (e) => {
    updateElement(element.set(field, e.target.value))
  }

  const fields = element.getIn(['template', 'fields'])

  if (fields.get(field) === undefined) {
    return null
  }

  const editable = fields.getIn([field, 'editable'])
  let value
  if (element.has(field)) {
    value = element.get(field)
  } else {
    const templateValue = element.getIn(['template', field])
    value = templateValue || element.getIn(['template', 'fields', field, 'default_value'])
  }

  return (
    <div key={field} className="col-sm-6 mb-2">
      <div className="row">
        <label className="col-4">{I18n.t(`activerecord.attributes.elements/base_element.${field}`)}:</label>
        <div className="col-8">
          <InputFactory
            field={field}
            name={name}
            value={value}
            editable={editable}
            handleInputChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  )
}
