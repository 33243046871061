import ProjectGroups from './project_groups'
import ProjectGroupShow from './project_group_show'
import Vendors from './vendors'
import VendorInstallers from './vendor_installers'
import Installers from './installers'

export default {
  ProjectGroups,
  ProjectGroupShow,
  Vendors,
  VendorInstallers,
  Installers
}
