import * as React from 'react'
import { format } from 'date-fns'
import Select from '../../../shared/base_react_select'

const { I18n } = window as any

type Option = {
  value: string
  label: string
}

interface OrderInformationProps {
  disabled: boolean
  value: {
    order_comments: string
    payment_partner: string
    receive_car_date: string
    delivery_location: string
    external_payment_partner: string
  } | null
  availableLocations: Option[]
}

export default function OrderInformation(props: OrderInformationProps) {
  const { disabled, value, availableLocations } = props
  const [paymentPartner, setPaymentPartner] = React.useState(value?.payment_partner || '')
  const [location, setLocation] = React.useState(value?.delivery_location || availableLocations[0].value)
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    value?.receive_car_date ? new Date(value.receive_car_date) : null
  )
  const [orderComments, setOrderComments] = React.useState(value?.order_comments || '')

  const paymentPartners = [
    { value: '37169', label: 'Mover Systems ApS' },
  ]

  const selectedLocation = location
    ? availableLocations.find((option) => option.value.toString() === location.toString())
    : availableLocations[0]

  const handleDateChange = (date: Date | null) => {
    if (disabled) return
    setSelectedDate(date)
  }

  const handleLocationsChange = (option: Option) => {
    if (disabled) return
    setLocation(option.value)
  }

  const handleDescriptionChange = (event) => {
    if (disabled) return
    setOrderComments(event.target.value)
  }

  return (
    <div>
      <div className="form-group">
        <div className="default-form-input-wrapper">
          <label className="label bold-headline">{I18n.t('order_requests.form.payment_partners')}</label>

          {paymentPartners.map((partner) => (
            <div key={partner.value} className="form-check inline">
              <label className={`btn btn-secondary mover-buttons ${paymentPartner === partner.value ? 'active' : ''}`}>
                <input
                  className="form-check-input hidden"
                  type="radio"
                  name="order_request[order_information][payment_partner]"
                  value={partner.value}
                  checked={paymentPartner === partner.value}
                  onChange={() => setPaymentPartner(partner.value)}
                  disabled={disabled}
                />
                {partner.label}
              </label>
            </div>
          ))}
        </div>

        <div className="form-group">
          <label className="label bold-headline">{I18n.t('order_requests.form.receive_car_date')}*</label>
          <small className="form-text text-muted" style={{ marginBottom: '8px', marginTop: '0px' }}>
            {I18n.t('order_requests.form.receive_car_date_hint')}
          </small>
          <div className="default-form-input-wrapper">
            <input
              type="date"
              name="order_request[order_information][receive_car_date]"
              value={selectedDate ? format(selectedDate, 'yyyy-MM-dd') : ''}
              onChange={(e) => handleDateChange(new Date(e.target.value))}
              className="form-control"
              disabled={disabled}
              required
              />
          </div>
        </div>

        <div className="form-group">
          <label className="label bold-headline">{I18n.t('order_requests.form.order_comments')}</label>
          <div className="default-form-input-wrapper">
            <textarea
              id="order_request_order_information_order_comments"
              className="form-control"
              value={orderComments}
              onChange={handleDescriptionChange}
              disabled={disabled}
            />
          </div>
        </div>

        <div className="form-group">
          <label className="label bold-headline">{I18n.t('order_requests.form.delivery_location')}</label>
          <Select
            options={availableLocations}
            value={selectedLocation}
            required
            onChange={handleLocationsChange}
            isDisabled={disabled}
          />
        </div>

        <input
          type="hidden"
          name="order_request[order_information][receive_car_date]"
          value={selectedDate ? selectedDate.toISOString().split('T')[0] : ''}
        />
        <input type="hidden" name="order_request[order_information][order_comments]" value={orderComments} />
        <input type="hidden" name="order_request[order_information][delivery_location]" value={location} />
      </div>
      <small className="form-text text-muted">{I18n.t('order_requests.new.submit_hint')}</small>
    </div>
  )
}
