import * as React from 'react'
import { useEffect, useState, useRef } from 'react'
import connect from './connect'
import { editor, view } from '@overlapmedia/imagemapper'

const { I18n } = window as any

type LocationOverviewInputProps = {
  imageUrl: string
  overviewData: any
  isAdmin: boolean
  locationAlbums: Array<any>
  locationElements: Array<any>
  elementsGroups: Array<any>
  locationId: number
}

const MODES: Array<'select' | 'polygon' | 'ellipse'> = ['select', 'polygon', 'ellipse']
const LINK_TYPES: Array<'Absolute link' | 'Element' | 'Location albums' | 'Element group'> = [
  'Absolute link',
  'Element',
  'Location albums',
  'Element group',
]

function LocationOverviewInput(props: LocationOverviewInputProps) {
  const { imageUrl, overviewData, isAdmin, locationAlbums, locationElements, elementsGroups, locationId } = props
  const elementRef = useRef<SVGSVGElement | null>(null)
  const instanceRef = useRef<any>(null)
  const [isEditMode, setIsEditMode] = useState(false)
  const [modeIndex, setModeIndex] = useState(0)
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 })
  const [exportedData, setExportedData] = useState<string>(overviewData.exported_data || '')
  const [textInputs, setTextInputs] = useState({})
  const [inputTypes, setInputTypes] = useState({})
  const [selectedComponentId, setSelectedComponentId] = useState(null)

  const idInterceptor = (id: string) => `${id}`

  useEffect(() => {
    if (!overviewData) {
      return
    }
    const links = overviewData.links

    const initialTextInputs = {}
    const initialInputTypes = {}

    if (links) {
      Object.keys(links).forEach((key) => {
        initialTextInputs[key] = links[key].link
        initialInputTypes[key] = links[key].type
      })
    }

    setTextInputs(initialTextInputs)
    setInputTypes(initialInputTypes)
  }, [overviewData])

  const handleTextInputChange = (id, value) => {
    setTextInputs({
      ...textInputs,
      [id]: value,
    })
  }

  const handleInputTypeChange = (id, value) => {
    setInputTypes({
      ...inputTypes,
      [id]: value,
    })
  }

  useEffect(() => {
    const img = new Image()
    img.src = imageUrl
    img.onload = () => {
      setImageSize({ width: img.naturalWidth, height: img.naturalHeight })
    }
  }, [imageUrl])

  useEffect(() => {
    if (elementRef.current && imageSize.width > 0 && imageSize.height > 0) {
      if (isEditMode) {
        instanceRef.current = editor(elementRef.current)
      } else {
        instanceRef.current = view(elementRef.current, {
          viewClickHandler: (e, id) => {
            if (isAdmin) {
              setSelectedComponentId(id)
            } else {
              handleLinkOpen(id)
            }
          },
        })
      }

      instanceRef.current.loadImage(imageUrl, imageSize.width, imageSize.height)
      if (exportedData) {
        hideSelectedAreas()

        instanceRef.current.import(exportedData, idInterceptor)

        JSON.parse(exportedData).components.forEach((component) => {
          if (!textInputs[component.id]) {
            setTextInputs((prevTextInputs) => ({
              ...prevTextInputs,
              [component.id]: 'Absolute link',
            }))
          }

          if (!isAdmin) {
            drawCircleTips(component)
          }
        })
      }
    }
  }, [imageUrl, imageSize, isEditMode, exportedData])

  useEffect(() => {
    if (isEditMode && instanceRef.current) {
      switch (MODES[modeIndex]) {
        case 'ellipse':
          instanceRef.current.ellipse()
          break
        case 'polygon':
          instanceRef.current.polygon()
          break
        case 'select':
          instanceRef.current.selectMode()
          break
        default:
          break
      }
    }
  }, [modeIndex, isEditMode])

  const handleModeSwitch = (e: React.MouseEvent) => {
    e.preventDefault()
    setModeIndex((prevIndex) => (prevIndex + 1) % MODES.length)
  }

  const saveShapeData = () => {
    if (isEditMode && instanceRef.current) {
      const data = instanceRef.current.export()
      setExportedData(data)
    }
  }
  const hideSelectedAreas = () => {
    if (!isAdmin) {
      instanceRef.current.setStyle({
        component: { fill: 'rgba(0, 0, 0, 0)', stroke: 'rgba(0, 0, 0, 0)' },
        componentHover: {
          off: { fill: 'rgba(0, 0, 0, 0)', stroke: 'rgba(0, 0, 0, 0)' },
          on: { fill: 'rgba(211, 211, 211, 0.5)', stroke: 'rgba(1, 1, 1, 0.5)' },
        },
      })
    }
  }

  const drawCircleTips = (component: any) => {
    let middleX = 0
    let middleY = 0
    if (component.type === 'polygon') {
      const points = component.data
      const n = points.length
      points.forEach((point) => {
        middleX += point.x
        middleY += point.y
      })
      middleX /= n
      middleY /= n
    } else if (component.type === 'ellipse') {
      middleX = component.data.x + component.data.width / 2
      middleY = component.data.y + component.data.height / 2
    }

    const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle')
    circle.setAttribute('cx', middleX.toString())
    circle.setAttribute('cy', middleY.toString())
    circle.setAttribute('r', '3')
    circle.setAttribute('fill', 'white')
    circle.setAttribute('stroke', 'black')
    circle.setAttribute('stroke-width', '1')
    circle.style.pointerEvents = 'none'
    elementRef.current?.appendChild(circle)
  }

  const toggleEditMode = (e: React.MouseEvent) => {
    e.preventDefault()
    if (isEditMode) {
      saveShapeData()
    }
    setIsEditMode(!isEditMode)
  }

  const getSecondSelectorOptions = (selectedType: string) => {
    switch (selectedType) {
      case 'Element':
        return locationElements.length > 0
          ? locationElements.map((element) => ({ value: element.value, label: element.label }))
          : [{ value: '', label: 'No elements available' }]
      case 'Location albums':
        return locationAlbums.length > 0
          ? locationAlbums.map((album) => ({ value: album.id, label: album.title }))
          : [{ value: '', label: 'No albums available' }]
      case 'Element group':
        return elementsGroups.length > 0
          ? elementsGroups.map((group) => ({ value: group.id, label: group.name }))
          : [{ value: '', label: 'No groups available' }]
    }
  }

  const handleLinkOpen = (id: string) => {
    const type = textInputs[id]
    const value = inputTypes[id]

    let url = ''
    switch (type) {
      case 'Element':
        url = `/locations/${locationId}/elements/${value}`
        break
      case 'Location albums':
        url = `/locations/${locationId}?view=images#album_${value}`
        break
      case 'Element group':
        url = `/locations/${locationId}?selected_group_id=${value}&view=elements#elements_group_${value}`
        break
      case 'Absolute link':
        url = value.startsWith('https') ? value : `https://${value}`
        break
      default:
        return
    }

    window.open(url, '_blank')
  }

  return (
    <div>
      <svg
        key={isEditMode ? 1 : 0}
        ref={elementRef}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={isAdmin ? imageSize.width : undefined}
        height={isAdmin ? imageSize.height : undefined}
        viewBox={`0 0 ${imageSize.width} ${imageSize.height}`}
        preserveAspectRatio="xMinYMin"
      ></svg>
      <br></br>
      {isEditMode && <small className="text-muted">{I18n.t('activerecord.attributes.overview.tooltip')}</small>}
      <div>
        {isEditMode ? (
          <>
            <button onClick={toggleEditMode}>{I18n.t('activerecord.attributes.overview.switch_to_view')}</button>
            <button onClick={handleModeSwitch}>
              {I18n.t('activerecord.attributes.overview.switch_to_shape', { current: MODES[modeIndex] })}
            </button>
          </>
        ) : (
          isAdmin && (
            <button onClick={toggleEditMode}>{I18n.t('activerecord.attributes.overview.switch_to_edit')}</button>
          )
        )}
      </div>
      <br></br>

      {isAdmin &&
        exportedData &&
        JSON.parse(exportedData).components.map((component) => (
          <li className="string input form-group optional stringish" key={component.id}>
            <div
              className={`default-form-input-wrapper flex-input-container ${
                selectedComponentId === component.id ? 'highlight-links' : ''
              }`}
            >
              <select
                value={textInputs[component.id] || ''}
                onChange={(e) => handleTextInputChange(component.id, e.target.value)}
                name={`overview[data][links][${component.id}][link]`}
                className="flex-input"
              >
                {LINK_TYPES.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
              {textInputs[component.id] === 'Absolute link' ? (
                <input
                  type="text"
                  value={inputTypes[component.id] || ''}
                  onChange={(e) => handleInputTypeChange(component.id, e.target.value)}
                  className="form-control flex-input"
                  name={`overview[data][links][${component.id}][type]`}
                />
              ) : (
                <select
                  value={inputTypes[component.id] || ''}
                  name={`overview[data][links][${component.id}][type]`}
                  className="form-control flex-input"
                  onChange={(e) => handleInputTypeChange(component.id, e.target.value)}
                >
                  {getSecondSelectorOptions(textInputs[component.id])?.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <br />
          </li>
        ))}
      <input type="hidden" name="overview[data][exported_data]" value={exportedData} />
    </div>
  )
}

export default connect(LocationOverviewInput)
