import * as React from 'react'
import { useState } from 'react'
import { fromJS } from 'immutable'
import { v4 as uuidv4 } from 'uuid'
import { elementSubtotal } from '../form/element'
import Photo from '../form/photo'
import ElementInput from '../form/element_input'

type InformationFieldProps = {
  caption: string
  field: string
  value: any
}

function InformationField(props: InformationFieldProps) {
  const { caption, field, value } = props
  if (value === undefined || value === '') {
    return null
  }
  return (
    <div className="col-sm-6 col-lg-4">
      <span className="title mr-2 font-weight-bold">{`${caption}: `}</span>
      {value}
    </div>
  )
}

type TemplatePreviewContentProps = {
  currentTemplateData: any
  addElement: (element: any, key: string) => void
  currentElement: any
  currentReplaceElementId: string
}

export default function TemplatePreviewContent(props: TemplatePreviewContentProps) {
  const { currentTemplateData, addElement, currentElement, currentReplaceElementId } = props

  const { I18n } = window as any
  const selectedElement =
    currentElement ||
    fromJS({
      key: uuidv4(),
      template: currentTemplateData,
      replace_element_id: currentReplaceElementId || null,
    })
  const [element, setElement] = useState(selectedElement)

  if (!element || (currentElement && element && element.get('key') !== currentElement.get('key'))) {
    setElement(selectedElement)
  }

  const fields = element.get('template').get('fields')
  const stockLevel = element.get('stock_level') || element.getIn(['template', 'stock_level'])
  const description = currentTemplateData && currentTemplateData.getIn(['fields', 'description', 'default_value'])
  const replaceText = currentReplaceElementId ? ` ${I18n.t('elements.order_form.replace_suffix')}` : ''
  const elementName = element.getIn(['template', 'fields', 'name', 'default_value']) + replaceText

  const infoFields = ['sku', 'sub_group', 'department', 'price'].map((field) => {
    const fieldDefinition = fields.get(field)
    let value = null
    if (fieldDefinition !== undefined) {
      const relationValue = fieldDefinition.get('relation_value')
      value = relationValue === undefined ? fieldDefinition.get('default_value') : relationValue
    }
    if (field === 'price') {
      value = parseFloat(value).toFixed(2)
    }
    const caption = I18n.t(`activerecord.attributes.elements/base_element.${field}`)
    return <InformationField key={`info-field-${field}`} caption={caption} field={field} value={value} />
  })
  const handleSubmit = (e) => {
    e.preventDefault()
    addElement(element, element.get('key'))
  }

  return (
    <div className="template-preview mt-3">
      <form onSubmit={handleSubmit}>
        <h3 className="preview-header">{elementName}</h3>
        <div className="template-preview-description">{description}</div>
        <div className="row mb-4">
          <div className="col-sm-4 col-lg-3">
            <Photo element={element} />
            <div className="subtotal mt-2">
              {I18n.t('activerecord.attributes.elements/base_element.subtotal')}:
              <span className="font-weight-bold">{elementSubtotal(element).toFixed(2)}</span>
            </div>
          </div>
          <div className="col-sm-8 col-lg-9">
            <div className="row">
              {infoFields}
              {(stockLevel || stockLevel == 0) && (
                <InformationField
                  key="info-field-stock"
                  caption={I18n.t('elements.order_form.stock_level')}
                  field="stock_level"
                  value={stockLevel}
                />
              )}
            </div>

            <div className="row mt-3">
              <ElementInput field="amount" element={element} name="amount" updateElement={setElement} />
              <ElementInput field="width" element={element} name="width" updateElement={setElement} />
              <ElementInput field="height" element={element} name="height" updateElement={setElement} />
              <ElementInput field="note" element={element} name="note" updateElement={setElement} />
            </div>

            <div>
              <button
                type="submit"
                className="add-element btn btn-success mt-3"
              >
                {currentElement ? I18n.t('elements.actions.update_element') : I18n.t('elements.actions.add_element')}
              </button>
            </div>
            <input type="hidden" name="template_id" value={element.get('template').get('id')} />
          </div>
          <hr className="col mt-4" />
        </div>
      </form>
    </div>
  )
}
