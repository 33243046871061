import { fromJS, Map, List } from 'immutable'
import { types } from 'actions/campaigns/locations_selector_actions'
import { some } from 'lodash'

const makeItemSelected = (state, itemId, selected) => {
  let locations = state.get('locations')
  const itemIndex = locations.findIndex((item) => item.get('id') == itemId)
  let updatedItem = locations.get(itemIndex).set('selected', selected)

  locations = locations.set(itemIndex, updatedItem)
  return state.set('locations', locations)
}

const makeAllSelected = (state, entityKey, selected) => {
  const items = state.get(entityKey).map((item) => item.set('selected', selected))
  return state.set(entityKey, items)
}

const selectAllLocations = (state) => {
  const items = state.get('locations').map((item) => {
    if (item.get('visible') === true) {
      return item.set('selected', true)
    }
    return item
  })
  return state.set('locations', items)
}

const locationIsVisible = (state, location, filtered_locations = null, tags = null) => {
  let filteredLocationIds = []
  if (filtered_locations !== null) {
    filteredLocationIds = filtered_locations.map((r) => r.id)
  }
  const tagIds =
    tags !== null
      ? tags.map((t) => t.value)
      : state
          .get('tags')
          .filter((t) => t.get('selected'))
          .map((t) => t.get('value'))
          .toJS()
  if (filteredLocationIds.length === 0 && tagIds.length === 0) {
    return true
  }

  const filteredLocationMatched =
    filteredLocationIds.length === 0 ? true : filteredLocationIds.includes(location.get('id'))
  const tagsMatched = tagIds.length === 0 ? true : some(tagIds, (tagId) => location.get('tagIDs').includes(tagId))

  return filteredLocationMatched && tagsMatched
}

const filterBy = (state, value) => {
  const filterValue = value.toLowerCase().trim()
  if (!filterValue) {
    return state.updateIn(['locations'], (locations) =>
      locations.map((loc) => loc.set('visible', true))
    )
  }

  const state_hash = state.toJS()
  const locations = state_hash.locations
  const regions = state_hash.regions

  const filteredLocations = locations.filter((location) => {
    return (
      location?.number?.toLowerCase().includes(filterValue) ||
      location.name?.toLowerCase().includes(filterValue) ||
      location.address?.zip?.toLowerCase().includes(filterValue) ||
      location.address?.city?.toLowerCase().includes(filterValue) ||
      location.address?.line1?.toLowerCase().includes(filterValue) ||
      location.address?.line2?.toLowerCase().includes(filterValue) ||
      location.address?.country?.toLowerCase().includes(filterValue)
    )
  })

  const filteredRegions = regions.filter((region) => {
    return region.label.toLowerCase().includes(filterValue)
  })

  const regionIds = filteredRegions.map((region) => region.value)
  const filteredByRegionLocations = locations.filter((location) => regionIds.includes(location.regionId))

  const combinedLocations = filteredLocations.concat(filteredByRegionLocations)
  const uniqueLocations = [...new Set(combinedLocations.map((location) => JSON.stringify(location)))].map(
    (stringified) => JSON.parse(stringified)
  )

  if (uniqueLocations.length === 0) {
    return state.updateIn(['locations'], (locations) => locations.map((loc) => loc.set('visible', false)))
  }

  return state.updateIn(['locations'], (locations) =>
    locations.map((loc) => loc.set('visible', locationIsVisible(state, loc, uniqueLocations)))
  )
}

const filterByTags = (state, tags) => {
  if (tags.length == 0) {
    return state
      .updateIn(['locations'], (locations) =>
        locations.map((loc) => loc.set('visible', locationIsVisible(state, loc, null, [])))
      )
      .updateIn(['tags'], (iTags) => iTags.map((r) => r.set('selected', false)))
  }

  const tagIds = tags.map((r) => r.value)
  return state
    .updateIn(['locations'], (locations) => {
      return locations.map((loc) => loc.set('visible', locationIsVisible(state, loc, null, tags)))
    })
    .updateIn(['tags'], (iTags) => iTags.map((r) => r.set('selected', tagIds.includes(r.get('value')))))
}

function reducer(state = new Map({}), action) {
  switch (action.type) {
    case types.INIT_BOXES:
      const { locations, tags, id, step, regions, isReady, approvedLocationIDs, campaignLocationsNeedApproval } = action
      return fromJS({ locations, tags, id, step, regions, isReady, approvedLocationIDs, campaignLocationsNeedApproval })
    case types.FILTER_BY:
      return filterBy(state, action.value)
    case types.FILTER_BY_TAGS:
      return filterByTags(state, action.tags)
    case types.SELECT_ITEM:
      return makeItemSelected(state, action.itemId, true)
    case types.SELECT_ALL:
      return selectAllLocations(state)
    case types.REMOVE_ITEM:
      return makeItemSelected(state, action.itemId, false)
    case types.REMOVE_ALL:
      return makeAllSelected(makeAllSelected(state, 'tags', false), 'locations', false)
    case types.SAVE_SELECTED:
      return state
  }
  return state
}

export default reducer
